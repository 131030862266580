import module from "../../assets/components-css/filltercouse.module.css";
import Aggrid from "./Aggrid";
import Cardcouse from "./Cardcouse";
import softskill from "../../assets/image/Soft Skill Thai.png";
import softskilleng from "../../assets/image/Soft Skill Eng.png"
import program from "../../assets/image/Computer IT Thai.png";
import programeng from "../../assets/image/Computer IT Eng.png";
import quality from "../../assets/image/quality thai.png";
import qualityeng from "../../assets/image/quality eng.png";
import law from "../../assets/image/law thai.png";
import laweng from "../../assets/image/law eng.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";

function Filtercouse() {
  const { t }: { t: (key: string) => string } = useTranslation();
const { i18n } = useTranslation();
  const [category, setcategory] = useState<number>(0);
  return (
    <div className={module.container}>
      <div className={module.imagedesktop}>
        <img
          src={i18n.language === "th" ? softskill : softskilleng}
          alt=""
          onClick={() => {
            setcategory(3);
          }}
        />
        <img
         src={i18n.language === "th" ? program : programeng}
          alt=""
          onClick={() => {
            setcategory(4);
          }}
        />
        <img
          src={i18n.language === "th" ? quality : qualityeng}
          alt=""
          onClick={() => {
            setcategory(1);
          }}
        />
        <img
          src={i18n.language === "th" ? law : laweng}
          alt=""
          onClick={() => {
            setcategory(2);
          }}
        />
      </div>
      <div className={module.cousemobile}>
        <Cardcouse />
      </div>
      <div className={module.cousedesktop}>
        <Aggrid category={category} setcategory={setcategory} />
      </div>
    </div>
  );
}

export default Filtercouse;
