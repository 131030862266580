import { useRef, useEffect } from "react";
import module from "../../assets/components-css/gridicon.module.css";
import isoicon1 from "../../assets/image/ISOicon1.png";
import isoicon2 from "../../assets/image/ISOicon2.png";
import isoicon3 from "../../assets/image/ISOicon3.png";
import icon1 from "../../assets/image/icon1.png";
import icon2 from "../../assets/image/icon2.png";
import icon3 from "../../assets/image/icon3.png";
import icon4 from "../../assets/image/icon4.png";
import tab from "../../assets/image/tab.png";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "../../utils/i18n";
function Gridicon() {
  const { t }: { t: (key: string) => string } = useTranslation();
  // กด Link แล้วเลื่อนหน้า
  const RefStandard = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const Standards = location.state?.Standard;
  useEffect(() => {
    if (Standards === "Standard") {
      RefStandard.current?.scrollIntoView({ 
      behavior: "smooth", 
      block:"center",});
    }
  }, [Standards]);

  return (
    <div className={module.container}>
      <div className={module.info}>
        <div className={module.gridinfo}>
          <div className={module.gridinfoshowblue}>
            <div className={module.imagegridblue}>
              <div className={module.circleborder}>
                <div className={module.imagecircle}>
                  <img src={icon1} alt="" />
                </div>
              </div>
            </div>
            <h1>1.</h1>
            <h3>{t("cousecard")}</h3>
            <p>{t("cousecarddetail1")}</p>
            <p>{t("cousecarddetail2")}</p>
            <p>{t("cousecarddetail3")}</p>
            <p>{t("cousecarddetail4")}</p>
          </div>
          <div className={module.gridinfoshowblack}>
            <div className={module.imagegridblack}>
              <div className={module.circleborderblack}>
                <div className={module.imagecircleblack}>
                  <img src={icon2} alt="" />
                </div>
              </div>
            </div>
            <h1>2.</h1>
            <h3>{t("cousecard2")}</h3>
            <p>{t("cousecarddetailcard1")}</p>
            <p>{t("cousecarddetailcard2")}</p>
            <p>{t("cousecarddetailcard3")}</p>
            <p>{t("cousecarddetailcard4")}</p>
            <p>{t("cousecarddetailcard5")}</p>
            
            
          </div>
          <div className={module.gridinfoshowblue}>
            <div className={module.imagegridblue}>
              <div className={module.circleborder}>
                <div className={module.imagecircle}>
                  <img src={icon3} alt="" />
                </div>
              </div>
            </div>
            <h1>3.</h1>
            <h3>{t("cousecard3")}</h3>
            <p>{t("carddetail1")}</p>
            <p>{t("carddetail2")}</p>
            <p>{t("carddetail3")}</p>
          </div>
          <div className={module.gridinfoshowblack}>
            <div className={module.imagegridblack}>
              <div className={module.circleborderblack}>
                <div className={module.imagecircleblack}>
                  <img src={icon4} alt="" />
                </div>
              </div>
            </div>
            <h1>4.</h1>
            <h3>{t("cousecard4")}</h3>
            <p>{t("cardcouse1")}</p>
            <p>{t("cardcouse2")}</p>
          </div>
        </div>
      </div>
      <div className={module.imagetab}>
        <img src={tab} alt="" />
      </div>
      <div className={module.isotext} >
        <h1>{t("isotext")}</h1>
      </div>
      <div className={module.iso}ref={RefStandard}>
        <div className={module.isoicon}>
          <div className={module.isoimage}>
            <img src={isoicon1} alt="" />
          </div>
          <div className={module.isotextcard}>
            <h2>ISO 9001:2015</h2>
            <p>{t("isocard1")}</p>
          </div>
        </div>
        <div className={module.isoicon}>
          <div className={module.isoimage}>
            <img src={isoicon2} alt="" />
          </div>
          <div className={module.isotextcard}>
            <h2>ISO 39001:2012</h2>
            <p>{t("isocard2")}</p>
          </div>
        </div>
        <div className={module.isoicon}>
          <div className={module.isoimage}>
            <img src={isoicon3} alt="" />
          </div>
          <div className={module.isotextcard}>
            <h2>Q Mark</h2>
            <p>{t("isocard3")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gridicon;
