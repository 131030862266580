
import module from "../../assets/components-css/sildeshow.module.css";
import Silde from "./Sildebar";
import "bootstrap/dist/css/bootstrap.min.css";
function Slideshow() {

 
  return (
    
    <div className={module.container}>
  
      {/*  Silde component */} <Silde/>
    </div>
  );
}
export default Slideshow;
