import React from "react";
import Navbar from "./components/layout/Navbar";
import Home from "./pages/home/Home";
import Footer from "./components/layout/Footer";
import "./assets/components-css/App.css";
import Aboutpage from "./pages/about/About";
import Map from "./components/contact/Map";
import Couse from "./pages/couse/Couse";
import Promotion from "./pages/promotion/Promotion";
import Portforilo from "./pages/portforilo/Portforilo";
import { Route, Routes } from "react-router-dom";
function App() {
  return (
    <div>
      <Navbar />
      <div className="padding">
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/Couse" element={<Couse />}></Route>
          <Route path="/about" element={<Aboutpage />}></Route>
          <Route path="/Map" element={<Map />}></Route>
          <Route path="/Promotion" element={<Promotion />}></Route>
          <Route path="/Portforilo" element={<Portforilo />}></Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
