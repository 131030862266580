import React, { useEffect, useState, useTransition } from "react";
import module from "../../assets/components-css/navbar.module.css";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";
// import image
import logo from "../../assets/image/logomobile.png";
import logo2 from "../../assets/image/logodesktop.png";
import hamburger from "../../assets/image/hamburger.png";
import thaiicon from "../../assets/image/thailand.png";
import english from "../../assets/image/english.png";

function Navbar() {
  const { t }: { t: (key: string) => string } = useTranslation();
  const { i18n } = useTranslation();

  const [scrollbar, setscrollbar] = useState(true);
  const [switchcase, setswitchcase] = useState(false); // เก็บค่าเปิดปิดแบบ switch

  const location = useLocation();
  const Cousenavbars = location.state?.Cousenavbar;
  const Aboutnavbars = location.state?.Aboutnavbar;
  const Contactnavbars = location.state?.Contactnavbar;

  // sessionStorage เก็บค่าไว้สำหรับ navbar ตอน refresh จะได้ไม่หาย
  const [navbarnumber, setnavbarnumber] = useState<number>(() => {
    return Number(sessionStorage.getItem("count")) || 1;
  });

  useEffect(() => {
    sessionStorage.setItem("count", navbarnumber.toString());
  }, [navbarnumber]);

  //  ปุ่ม แปล ไทย eng
  function changelanguage(language: string) {
    i18n.changeLanguage(language);
    setswitchcase(false);
  }

  function activehamberger() {
    setswitchcase(true);
    if (switchcase === true) {
      setswitchcase(false);
    }
  }
  //  Re Render ของการcopy link แล้ว navbarunderlineไม่ตาม
  useEffect(() => {
    if (window.location.hash === "#/") {
      setnavbarnumber(1);
    }
  });

  useEffect(() => {
    if (window.location.hash === "#/About") {
      setnavbarnumber(2);
    }
  });
  useEffect(() => {
    if (window.location.hash === "#/Couse") {
      setnavbarnumber(3);
    }
  });

  useEffect(() => {
    if (window.location.hash === "#/Promotion") {
      setnavbarnumber(4);
    }
  });

  useEffect(() => {
    if (window.location.hash === "#/Portforilo") {
      setnavbarnumber(5);
    }
  });

  useEffect(() => {
    if (window.location.hash === "#/Map") {
      setnavbarnumber(6);
    }
  });

  // Function สำหรับ navbar กดแล้วเปิดปิดกับ เส้นใต้ตอนอยู่แต่ละหน้า
  function navbaractiveshow(number: number) {
    window.scrollTo({
      top: 0,
    });
    setnavbarnumber(number);
    setswitchcase(false);
  }

  // ป้องกัน re render สำหรับ navbar footer link
  useEffect(() => {
    if (Cousenavbars === "Cousenavbar") {
      setnavbarnumber(3);
    }
    window.scrollTo({
      top: 0,
    });
  }, [Cousenavbars]);
  useEffect(() => {
    if (Aboutnavbars === "Aboutnavbar") {
      setnavbarnumber(2);
    }
    window.scrollTo({
      top: 0,
    });
  }, [Aboutnavbars]);
  useEffect(() => {
    if (Contactnavbars === "Contactnavbar") {
      setnavbarnumber(6);
    }
    window.scrollTo({
      top: 0,
    });
  }, [Contactnavbars]);

  // สำหรับการเลื่อนแล้ว setscrollbar
  const scrollshow = () => {
    if (window.scrollY > 20) {
      setscrollbar(false);
    } else if (window.scrollY < 20) {
      setscrollbar(true);
    }
  };

  //  สำหรับการเลื่อน scroll
  useEffect(() => {
    window.addEventListener("scroll", scrollshow);
  });
  return (
    <div className={scrollbar === true ? module.container : module.containers}>
      <div className={scrollbar === true ? module.logo : module.logos}>
        <div className={module.logomobile}>
          <img src={logo} alt="" />
        </div>
        <div className={module.logodesktop}>
          <img src={logo2} alt="" />
        </div>
      </div>
      <div className={module.searhhamberger}>
        <div
          className={
            switchcase === true ? module.hambergeractive : module.hamberger
          }
          onClick={activehamberger}
        >
          <img src={hamburger} alt="" />
        </div>
      </div>
      <div
        className={
          switchcase === true ? module.sildebaractive : module.sildebar
        }
      >
        <Link
          to="/"
          className={
            navbarnumber === 1
              ? module.sildebarcontentactive
              : module.sildebarcontent
          }
          onClick={() => {
            navbaractiveshow(1);
          }}
        >
          <p>{t("Home")}</p>
        </Link>
        <Link
          to="/About"
          className={
            navbarnumber === 2
              ? module.sildebarcontentactive
              : module.sildebarcontent
          }
          onClick={() => {
            navbaractiveshow(2);
          }}
        >
          <p>{t("About")}</p>
        </Link>
        <Link
          to="/Couse"
          className={
            navbarnumber === 3
              ? module.sildebarcontentactive
              : module.sildebarcontent
          }
          onClick={() => {
            navbaractiveshow(3);
          }}
        >
          <p>{t("Couse")}</p>
        </Link>
        <Link
          to="/Promotion"
          className={
            navbarnumber === 4
              ? module.sildebarcontentactive
              : module.sildebarcontent
          }
          onClick={() => {
            navbaractiveshow(4);
          }}
        >
          <p>{t("Promotion")}</p>
        </Link>
        <Link
          to="/Portforilo"
          className={
            navbarnumber === 5
              ? module.sildebarcontentactive
              : module.sildebarcontent
          }
          onClick={() => {
            navbaractiveshow(5);
          }}
        >
          <p>{t("Port")}</p>
        </Link>
        <Link
          to="/Map"
          className={
            navbarnumber === 6
              ? module.sildebarcontentactive
              : module.sildebarcontent
          }
          onClick={() => {
            navbaractiveshow(6);
          }}
        >
          <p>{t("Contact")}</p>
        </Link>
        <div className={module.sildebarcontent}>
          <div
            className={module.languageshow}
            onClick={() => changelanguage(i18n.language === "th" ? "en" : "th")}
          >
            <img src={i18n.language === "th" ? thaiicon : english} alt="" />
            <p>{t("Language")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
