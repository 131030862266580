import module from "../../assets/components-css/card.module.css";
import ISO1 from "../../assets/image/ISO1.png"
import ISO2 from "../../assets/image/ISO2.png"
import ISO3 from "../../assets/image/ISO3.png"
import ISO1eng from "../../assets/image/ISO1eng.png"
import ISO2eng from "../../assets/image/ISO2eng.png"
import ISO3eng from "../../assets/image/ISO3eng.png"
import background from "../../assets/image/BACKGROUND2.png"
import { useTranslation } from "react-i18next";
import "../../utils/i18n";
function Card() {
  const { t }: { t: (key: string) => string } = useTranslation();
   const { i18n } = useTranslation();
  return (
    <div className={module.container}>
      <div className={module.card}>
        <div className={module.header}>
          <h1>{t("isoheader")}</h1>
        </div>
        <div className={module.cardimg}>
          <img src={i18n.language === "th" ? ISO1 : ISO1eng} alt="" />
          <img src={i18n.language === "th" ? ISO2 : ISO2eng} alt="" />
          <img src={i18n.language === "th" ? ISO3 : ISO3eng} alt="" />
        </div>
      
      </div>
      <img src={background} alt="" className={module.background}/>
    </div>
  );
}

export default Card;
