import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import hometh from "../utils/HomeTranslationth/home.json"
import homeen from "../utils/HomeTranslationen/home.json"

const resources = {
  en: {
    translation: homeen

  },
  th: {
    translation: hometh
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "th", // ค่าเริ่มต้นเป็นภาษาอังกฤษ
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // ไม่ต้อง Escape HTML
    },
  });

export default i18n;
