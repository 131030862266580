import module from "../../assets/components-css/bigimage.module.css";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";

import background from "../../assets/image/backroundpage1.png";
function Bigimage() {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <div className={module.container}> 
      <div className={module.background}>
        <div className={module.text}>
          <h1 className={module.white}>{t("companyabout")}</h1>
          <h1 className={module.yellow}>INFINITY R&D COMPANY LIMITED</h1>
          <p>{t("companytext")}</p>
        </div>
      </div>
      <img src={background} alt="" />
      </div>
  );
}

export default Bigimage;
