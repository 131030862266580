import React, { useEffect, useState, useRef } from "react";
import module from "../../assets/components-css/cardcouse.module.css";
import { Couseshow } from "../../utils/datacouse";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";

interface tablefetch {
  id: string;
  category: string;
  codecouse: string;
  namecouse: { [key: string]: string };
  lecturer: string;
  detail: string;
  imagebackground: { [key: string]: string };
}

function Cardcouse() {
  const { i18n } = useTranslation();
  const { t }: { t: (key: string) => string } = useTranslation();
  const Refmobile = useRef<HTMLDivElement>(null);
  const [inputFilter, setInputFilter] = useState(""); // Input form
  const [optionData, setOptionData] = useState(""); // Dropdown
  const [filterQuery, setfilterQuery] = useState({ name: "", category: "" }); // ใช้กรองข้อมูลจริง
  const [dataFetch, setDataFetch] = useState<tablefetch[]>([]); // เก็บข้อมูลหลักสูตร
  const [currentPage, setCurrentPage] = useState(1); // หน้า Pagination
  const itemsPerPage = 5; // จำนวนรายการต่อหน้า
  const location = useLocation();
  const categoryss = location.state?.categoryss;


  useEffect(() => {
    setDataFetch(Couseshow);
  }, []);
  useEffect(() => {
    const categoryMap: { [key: number]: string } = {
      1: "Quality",
      2: "Legal_Compliance",
      3: "Soft Skills",
      4: "Computer",
    };
    if (categoryss) {
      setOptionData(categoryMap[categoryss] || "");
    }
    if (Refmobile.current) {
      Refmobile.current.scrollTo({
     top:0,
     behavior: "smooth",
      });
    }
  }, [categoryss]);

  // กรองข้อมูลตามค่าที่ Submit
  const filteredData = dataFetch.filter((show) => {
    const languageinput = i18n.language == "th"? 
    show.namecouse.th.toString().toLowerCase().includes(filterQuery.name.toLowerCase()) :
    show.namecouse.en.toString().toLowerCase().includes(filterQuery.name.toLowerCase())
    return (
      languageinput &&   
       (filterQuery.category === "" || show.category.toLowerCase().includes(filterQuery.category.toLowerCase()))
    );
  });
  
  // คำนวณหน้า Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  // คำนวณหน้าทั้งหมด
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // ฟังก์ชัน Submit ฟิลเตอร์
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setfilterQuery({ name: inputFilter, category: optionData });
    setCurrentPage(1);
  };
  const getPaginationNumbers = () => {
    let pages: number[] = [];
    const range = 3;
    let startPage = currentPage - range;
    let endPage = currentPage + range;
    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(6, totalPages);
    }
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - 5);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  return (
    <div ref={Refmobile} className={module.container}>
      <form onSubmit={handleSubmit}>
        <div  className={module.option}>
          <select
            value={optionData}
            onChange={(e) => setOptionData(e.target.value)}>
            <option value="">{t("select")}</option>
            <option value="Quality">{t("Quality")}</option>
            <option value="Legal_Compliance">{t("Legal_Compliance")}</option>
            <option value="Soft Skills">Soft Skills</option>
            <option value="Computer">{t("Computer")}</option>
          </select>
        </div>
        <div className={module.inputsubmit}>
          <input
            type="text"
            placeholder={t("selectinput")}
            value={inputFilter}
            onChange={(e) => setInputFilter(e.target.value)}
          />
        </div>
        <div className={module.submitbouttonandclear}>
          <button
            className={module.clear}
            type="button"
            onClick={() => {
              setInputFilter("");
              setOptionData("");
              setfilterQuery({ name: "", category: "" });
              setCurrentPage(1);
            }}
          >
            <p>{t("clear")}</p>
          </button>
          <button type="submit" className={module.submit}>
            <p>{t("search")}</p>
          </button>
        </div>
      </form>

      <div className={module.cardcontainer}>
        {currentItems.length > 0 ? (
          currentItems.map((items, index) => (
            <div className={module.couse} key={index}>
              <div className={module.imagebackground}>
                <img src={items.imagebackground[i18n.language]} alt="" />
              </div>
              <div className={module.detailall}>
                <div className={module.namecouseandborder}>
                  <h5>{items.namecouse[i18n.language]}</h5>
                  <div className={module.border}></div>
                </div>
                <div className={module.detail}>
                  <div className={module.text}>
                    <li>{t("codecousetext")} {items.codecouse}</li>
                    <li>{t("lecturer")}{t("lecturertable")}</li>
                  </div>
                  <div className={module.button}>
                    <button>{t("Btnview")}</button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>{t("no_result")}</p>
        )}
      </div>

      {totalPages > 1 && (
        <div className={module.allpagenation}>
          <div className={module.pagination}>
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              {t("pervious")}
            </button>

            {getPaginationNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={currentPage === page ? module.activePage : ""}
              >
                {page}
              </button>
            ))}

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              {t("Next")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cardcouse;
