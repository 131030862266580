import AboutUs from "../../components/home/AboutUs"
import News from "../../components/home/News"
import Slideshow from "../../components/home/Slideshow"
import Card from "../../components/home/Card"
import Searchaccordian from "../../components/home/Searchaccordian"
export default function Home() {
    return(
        <>
            <Slideshow />
            <AboutUs />
            <News />
            <Card />
            <Searchaccordian />
        </>
    )
};
