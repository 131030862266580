import { useState, useEffect } from "react";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { Couseshow } from "../../utils/datacouse";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import iconsearch from "../../assets/image/iconsearch.png";
import "../../assets/components-css/table.css";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";
import React from "react";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});


type language = {
  th: string;
  en: string;
};
type image = {
  th: string;
  en: string;
};

type rowdata = {
  id: any;
  imagebackground: image;
  category: string;
  codecouse: string;
  namecouse: language;
  lecturer: string;
  detail: string;
};

export default function Aggrid({ category, setcategory }: any) {
  const { t }: { t: (key: string) => string } = useTranslation();
  const { i18n } = useTranslation();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20,
  });




  const columns: GridColDef<rowdata>[] = [
    {
      field: "id",
      maxWidth: 60,
      headerName: t("No"),
      headerClassName: "header-style",
      cellClassName: "cell-style",
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      headerAlign: "left",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value}</p>; // Custom cell renderer
      },
    
    },
    {
      field: "category",
      headerName: t("categoryheader"),
      maxWidth: 230,
      headerClassName: "header-style",
      cellClassName: "cell-style",
      disableColumnMenu: true,
      headerAlign: "left",
      sortable:true,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return <p>{params.value}</p>; // Custom cell renderer
      },
      valueGetter: (params: string) =>{
            return t(params)
      }
    },
    {
      field: "codecouse",
      headerName: t("Codecouse"),
      maxWidth: 130,
      headerClassName: "header-style",
      cellClassName: "cell-style",
      headerAlign: "left",
      disableColumnMenu: true,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return <p>{t(params.value)}</p>; // Custom cell renderer, makes name bold
      },
    },
    {
      field: "namecouse",
      headerName: t("Namecouse"),
      maxWidth: 10000,
      headerClassName: "header-style",
      cellClassName: "cell-style",
      headerAlign: "left",
      disableColumnMenu: true,
      flex: 1,
      align: "left",
      valueGetter: (params: language) =>
        (i18n.language == "th" ? params.th : params.en) || "",
      renderCell: (params: GridCellParams) => {
        const value = params.row.namecouse[i18n.language]; // ใช้ค่าภาษาที่เลือกใน renderCell
        return <p>{value}</p>;
      },
    },
    {
      field: "lecturer",
      headerName: t("Lecturer"),
      maxWidth: 250,
      headerClassName: "header-style",
      cellClassName: "cell-style",
      headerAlign: "left",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return <p>{t(params.value)}</p>; // Custom cell renderer, makes name bold
      },
    },
    {
      field: "detail",
      headerName: t("Detail"),
      maxWidth: 140,
      headerClassName: "header-style",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerAlign: "center",
      cellClassName: "cell-style",
      renderCell: (params) => (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => handleDetailClick()}
            className="fonttable"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#F06548",
              fontSize: "11px",
              border: "none",
              cursor: "pointer",
              borderRadius: 5,
              color: "White",
              height: 26,
              width: 80,
            }}
          >
            {t("Btnview")}
          </button>
        </div>
      ),
    },
  ];

  // ปุ่มสำหรับต้องการให้แสดงข้อมูลเพิ่มเติม
  const handleDetailClick = () => {};
  const location = useLocation();
  const categoryss = location.state?.categoryss;

  //  มีไว้สำหรับป้องกันการ re render ของ setSelectedCategory

  useEffect(() => {
    setcategory(categoryss);
    setPage(1);
  }, [categoryss]);

  useEffect(() => {
    if (Number(category) === 1) {
      setSelectedCategory("Quality");
    } else if (Number(category) === 2) {
      setSelectedCategory("Legal_Compliance");
    } else if (Number(category) === 3) {
      setSelectedCategory("Soft Skills");
    } else if (Number(category) === 4) {
      setSelectedCategory("Computer");
    } else {
      setSelectedCategory("");
    }
    // รีเซ็ต page กลับเป็น 1 ทุกครั้งที่ category หรือ categoryss เปลี่ยนแปลง
    setPage(1);
   
  }, [category]); // เพิ่ม categoryss ใน dependencies

  const [allcouse, setallcouse] = useState(Couseshow);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");

  const itemsPerPage = 20;

  useEffect(() => {
    setPaginationModel({
      page: page - 1,
      pageSize: 20,
    });
  }, [page]);

  const filteredData = allcouse
    .filter((course) => {
      const isCategoryMatch = selectedCategory? course.category === selectedCategory: true;
      const isSearchTermMatch = i18n.language == "en" ? course.namecouse.en.toLowerCase().includes(searchTerm.toLowerCase()) : course.namecouse.th.toLowerCase().includes(searchTerm.toLowerCase());
        return isCategoryMatch && isSearchTermMatch;
      
    })
    .map((item, index) => {
      return { ...item, id: index + 1 };
    });
//  สำหรับเวลากด pagenation แล้ว search แล้วไม่ยอมไปหน้า1
    useEffect(() => {
      setPage(1);
    }, [searchTerm]);

  // สร้างรายการหมวดหมู่ที่ไม่ซ้ำ
  const categories = [...new Set(allcouse.map((course) => course.category))];

  // usePagination based on filteredData length
  const { items } = usePagination({
    
    count: Math.ceil(filteredData.length / itemsPerPage), // Use filteredData length
    page,
    onChange: (e, value) => setPage(value),
   
  });

  const selectedOptionLabel = selectedCategory ? selectedCategory : t("All");

  return (
    <div  style={{ height: "100%", width: "80%" }}>
      <div className="input">
        <div className="option">
          <div className="custom-select">
            <span className="select-label">{`${t("category")} : ${t(
              selectedOptionLabel
            )}`}</span>
            <select
              value={category}
              onChange={(e) => setcategory(e.target.value)}
            >
              <option value="0">{t("All")}</option>
              {categories.map((category, index) => (
                <option key={index} value={index + 1}>
                  {t(category)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="inputsearch">
          <div className="searchimage">
            <img src={iconsearch} alt="" />
          </div>
          <input
            type="text"
            placeholder={t("Searchplanceholder")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="search"></div>
      <DataGrid
        rows={filteredData}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
        pagination
        hideFooter

        slots={{
          columnSortedAscendingIcon: (props) => (
            <ArrowDropUp {...props} sx={{ color: "white" }} />
          ),
          columnSortedDescendingIcon: (props) => (
            <ArrowDropDown {...props} sx={{ color: "white" }} />
          ),
        }}
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />

      <nav>
        <List>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "…";
            } else if (type === "page") {
              children = (
                <button
                  type="button"
                  style={{
                    color: selected ? "white" : undefined,
                    backgroundColor: selected ? "#1E204E" : undefined,
                    width: "65%",
                    minHeight: "65%",
                    maxHeight: "100%",
                    transition: "0.5s",
                    borderRadius: "5px",
                    fontSize:"1rem"
                  }}
                  {...item}
                >
                  {page}
                </button>
              );
            } else if (type === "previous") {
              children = (
                <button type="button" style={{}} {...item}>
                  {t("pervious")}
                </button>
              );
            } else if (type === "next") {
              children = (
                <button type="button" style={{ width: "100%",  fontSize:"1rem" }} {...item}>
                  {t("Next")}
                </button>
              );
            } else {
              children = (
                <button type="button" style={{ width: "100%",  fontSize:"1rem" }} {...item}>
                  {type}
                </button>
              );
            }
            return <li key={index}>{children}</li>;
          })}
        </List>
      </nav>
    </div>
  );
}
