import module from "../../assets/components-css/imagecouse.module.css";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
function Imagecouse() {
  const location = useLocation();
  const categoryss = location.state?.categoryss;
  const divRef = useRef<HTMLDivElement>(null);
  const { t }: { t: (key: string) => string } = useTranslation();
  const { i18n } = useTranslation();

  useEffect(() => {
  const scrollwidth = window.innerWidth;
    if (scrollwidth < 1024) {
      if (divRef.current) {
        const elementTop =
          divRef.current.getBoundingClientRect().top + window.pageYOffset;
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
        window.scrollTo({
          top: -100,
          behavior: "smooth",
        });
      }
    }
  }, [categoryss]);

  return (
    <div className={module.container} ref={divRef} >
      <div
        className={
          i18n.language === "th" ? module.fullimage : module.fullimageeng
        }
      ></div>
      <div className={module.containertext}>
        <div  className={module.cousetext}>
          <div className={module.text}>
            <h1>{t("cousetext")}</h1>
          </div>
          <div className={module.color}>
            <div className={module.colorright}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Imagecouse;
