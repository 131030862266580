import module from "../../assets/components-css/sildeshow.module.css";
import silde1 from "../../assets/image/silde1.jpg";
import silde1eng from "../../assets/image/silde1eng.jpg";
import silde2 from "../../assets/image/silde2.jpg";
import silde3 from "../../assets/image/silde3.jpg";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";

function DarkVariant() {
  const { i18n } = useTranslation();
  return (
    <Carousel
      data-bs-theme="dark"
      className={module["carousel-custom"]}
      controls={false}
    >
      <Carousel.Item>
        {/* Image for the first slide */}
        <img // Bootstrap classes for responsive full-width image
          // Placeholder image URL
          alt="First slide" // Alt text for accessibility
          src={i18n.language === "th" ? silde1 : silde1eng}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          alt="Second slide"
          // Bootstrap classes for responsive full-width image
          src={silde2} // Placeholder image URL
        />
      </Carousel.Item>
      <Carousel.Item className={module.silde}>
        <img src={silde3} alt="Third slide" />
      </Carousel.Item>
    </Carousel>
  );
}

export default DarkVariant;
