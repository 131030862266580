import React from "react";
import module from "../../assets/components-css/Portfolio.module.css";
import colorslash from "../../assets/image/colorslash.png";
import port1 from "../../assets/image/port1.jpg";
import port2 from "../../assets/image/port2.jpg";
import port3 from "../../assets/image/port3.jpg";
import port4 from "../../assets/image/port4.jpg";
import port5 from "../../assets/image/port5.jpg";
import port6 from "../../assets/image/port6.jpg";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";

function Portfolio() {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <div className={module.container}>
      <div className={module.portfoliotext}>
        <h1>{t("Portfolio")}</h1>
        <p>{t("Portfoliotext")}</p>
        <img src={colorslash} alt="" />
      </div>
      <div className={module.portfoliogridimage}>
        <div className={module.background}></div>
        <div className={module.gridcontent}>
          <div className={module.grid1}>
            <img src={port1} className={module.port1} alt="" />
            <img src={port4} className={module.port2} alt="" />
            
          </div>
          <div className={module.grid2}>
            <img src={port2} className={module.port3} alt="" />
            <img src={port5} className={module.port4} alt="" />
            
          </div>
          <div className={module.grid3}>
            <img src={port3} className={module.port5} alt="" />
            <img src={port6} className={module.port6} alt="" />
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
