import Imagecouse from "../../components/couse/Imagecouse";
import Filltercouse from "../../components/couse/Filtercouse"
import module from "../../assets/components-css/couse.module.css";
function Couse() {
  return (
    <div className={module.container}>
        <Imagecouse />
        <Filltercouse/>
    </div>
  );
}

export default Couse;
