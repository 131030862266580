import couse1 from "../assets/image/couse1.jpg"
import couse1eng from "../assets/image/couse1eng.png"
import couse2 from "../assets/image/couse2.jpg"
import couse2eng from "../assets/image/couse2eng.png"
import couse3 from "../assets/image/couse3.jpg"
import couse3eng from "../assets/image/couse3eng.png"
import couse4 from "../assets/image/couse4.png"
import couse4eng from "../assets/image/couse4eng.png"
import couse5 from "../assets/image/couse5.jpg"
import couse5eng from "../assets/image/couse5eng.png"
import couse6 from "../assets/image/couse6.jpg"
import couse6eng from "../assets/image/couse6eng.png"

const Dataaccordian = [
  {
    image: {
      th: couse1,
      en: couse1eng,
    },
    category: {
      th: "ด้านระบบคุณภาพ",
      en: "Quality Management System",
    },
    header: {
      th: "หลักสูตร ข้อกำหนดและความตะหนักด้านคุณภาพ",
      en: "Quality Requirements and Awareness",
    },
    text: {
      th: "ปัจจุบัน การบริหารคุณภาพเป็นสิ่งสำคัญในการแข่งขันทางธุรกิจ หลักสูตรนี้ช่วยให้ผู้เรียนเข้าใจข้อกำหนด ISO 9001:2015 และสามารถนำไปปรับใช้ในการพัฒนาคุณภาพและประสิทธิภาพขององค์กรได้อย่างมีประสิทธิผล",
      en: "In today’s business landscape, quality management is crucial for maintaining competitiveness. This course equips learners with a thorough understanding of ISO 9001:2015 requirements and provides practical guidance on applying them to enhance organizational quality and efficiency effectively. ",
    },
   
  },
  {
    image: {
      th: couse2,
      en: couse2eng,
    },
    category: {
      th: "ด้านระบบคุณภาพ",
      en: "Quality Management System",
    },
    header: {
      th: "หลักสูตร การประเมินความเสี่ยงและโอกาส",
      en: "Risk and Opportunity Assessment",
    },
    text: {
      th: "ในโลกธุรกิจที่เปลี่ยนแปลงอย่างรวดเร็ว องค์กรต้องเผชิญกับความเสี่ยงและโอกาสที่ปรับเปลี่ยนตลอดเวลา หลักสูตรนี้ช่วยให้ผู้เรียนมีทักษะในการประเมินและบริหารความเสี่ยง พร้อมการใช้โอกาสในการพัฒนาองค์กรตามมาตรฐาน ISO 9001:2015",
      en: "In today’s business landscape, quality management is crucial for maintaining competitiveness. This course equips learners with a thorough understanding of ISO 9001:2015 requirements and provides practical guidance on applying them to enhance organizational quality and efficiency effectively.In a rapidly evolving business world, organizations face ever-changing risks and opportunities. This course provides learners with the skills to assess and manage risks effectively while leveraging opportunities to drive organizational growth in alignment with ISO 9001:2015 standards. ",
    },
 
  },
  {
    image: {
      th: couse3,
      en: couse3eng,
    },
    category: {
      th: "ด้านระบบคุณภาพ",
      en: "Quality Management System",
    },
    header: {
      th: "หลักสูตร การตรวจติดตามคุณภาพภายใน",
      en: "Internal Audit",
    },
    text: {
      th: "การตรวจติดตามคุณภาพเป็นสิ่งสำคัญในการรักษามาตรฐานและประสิทธิภาพขององค์กร หลักสูตรนี้ช่วยให้ผู้เรียนสามารถตรวจติดตามและประเมินกระบวนการภายในองค์กรตามข้อกำหนดของ ISO 9001:2015 เพื่อพัฒนาและรักษาคุณภาพอย่างต่อเนื่อง",
      en: "Quality auditing is essential for maintaining organizational standards and performance. This course enables learners to monitor and assess internal processes in compliance with ISO 9001:2015 requirements, fostering continuous quality improvement and sustainability. ",
    },
    
  },
  {
    image: {
      th: couse4,
      en: couse4eng,
    },
    category: {
      th: "Soft Skills",
      en: "Soft Skills",
    },
    header: {
      th: "หลักสูตร บริหารเชิงกลยุทธ์",
      en: "Strategic Management",
    },
    text: {
      th: "ยุคแห่งการเปลี่ยนแปลงอย่างต่อเนื่อง องค์กรต้องมีการวางแผนกลยุทธ์เพื่อรับมือกับการแข่งขันและการเปลี่ยนแปลง หลักสูตรนี้ช่วยให้ผู้เรียนเข้าใจการบริหารเชิงกลยุทธ์ เพื่อขับเคลื่อนองค์กรให้เติบโตและประสบความสำเร็จในระยะยาว",
      en: "In an era of constant change, organizations must develop strategic plans to navigate competition and evolving challenges. This course provides learners with a comprehensive understanding of strategic management, empowering them to drive sustainable growth and long-term success. ",
    },
  
  },
  {
    image: {
      th: couse5,
      en: couse5eng,
    },
    category: {
      th: "Soft Skills",
      en: "Soft Skills",
    },
    header: {
      th: "หลักสูตร Balanced Scorecard & KPIS",
      en: "Balanced Scorecard & KPIS",
    },
    text: {
      th: "โลกที่ขับเคลื่อนด้วยข้อมูลและการเปลี่ยนแปลงอย่างรวดเร็ว องค์กรต้องสามารถวัดผลและปรับกลยุทธ์ได้อย่างมีประสิทธิภาพ หลักสูตรนี้ช่วยให้ผู้เรียนเข้าใจการใช้ Balanced Scorecard และ KPIs เพื่อวัดผลและขับเคลื่อนการดำเนินงานไปสู่เป้าหมายที่ชัดเจนและมีประสิทธิภาพ",
      en: "In a data-driven world marked by rapid change, organizations must measure performance and adapt strategies effectively. This course equips learners with the knowledge to use Balanced Scorecard and KPIs to measure outcomes and drive operations toward clear and efficient goals. ",
    },
  },
  {
    image: {
      th: couse6,
      en: couse6eng,
    },
    category: {
      th: "Soft Skills",
      en: "Soft Skills",
    },
    header: {
      th: "หลักสูตร Competency Based Management",
      en: "Competency Based Management ",
    },
    text: {
      th: "สภาวะการทำงานที่มีความท้าทายและซับซ้อน องค์กรต้องการบุคลากรที่มีสมรรถนะหลากหลายเพื่อตอบสนองต่อความต้องการ หลักสูตรนี้ช่วยให้ผู้เรียนสามารถบริหารและพัฒนาทรัพยากรมนุษย์ โดยใช้สมรรถนะเป็นพื้นฐาน เพื่อเพิ่มประสิทธิภาพและความสามารถในการแข่งขัน",
      en: "In a complex and challenging work environment, organizations require a diverse skill set from their workforce to meet demands. This course enables learners to manage and develop human resources based on competencies to enhance efficiency and competitiveness. ",
    },
  
  },
];
export { Dataaccordian };
