import quality from "../assets/image/bannercouse/quality.png";
import qualityeng from "../assets/image/bannercouse/quality eng.png";
import softskill from "../assets/image/bannercouse/Soft Skill Thai.png";
import softskilleng from "../assets/image/bannercouse/Soft Skill Eng.png";
import law from "../assets/image/bannercouse/law.png";
import laweng from "../assets/image/bannercouse/law eng.png";
import it from "../assets/image/bannercouse/it.png";
import iteng from "../assets/image/bannercouse/it eng.png";

const Couseshow = [
  {
    id: "1",
    imagebackground: {
      th: quality,
      en: qualityeng,
    },
    category: "Quality",
    codecouse: "ISO9001-001",
    namecouse: {
      th: "ข้อกำหนดและความตะหนักด้านคุณภาพ (ISO9001:2015)",
      en: "Requirement and awareness in Quality Management System (ISO9001:2015)",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "2",
    imagebackground: {
      th: quality,
      en: qualityeng,
    },
    category: "Quality",
    codecouse: "ISO9001-002",
    namecouse: {
      th: "การประเมินความเสี่ยงและโอกาส (ISO9001:2015)",
      en: "Risk and Opportunities Assessment (ISO 9001:2015)",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "3",
    imagebackground: {
      th: quality,
      en: qualityeng,
    },
    category: "Quality",
    codecouse: "ISO9001-003",
    namecouse: {
      th: "การตรวจติดตามคุณภาพภายใน (ISO9001:2015)",
      en: "Internal Audit (ISO 9001:2015)",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "4",
    imagebackground: {
      th: law,
      en: laweng,
    },
    category: "Legal_Compliance",
    codecouse: "L001",
    namecouse: {
      th: "เจ้าหน้าที่ความปลอดภัยในการทำงานระดับหัวหน้างาน (จป.หัวหน้างาน)",
      en: "Supervisor-Level Occupational Safety Officer (OSO Supervisor)",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "5",
    imagebackground: {
      th: law,
      en: laweng,
    },
    category: "Legal_Compliance",
    codecouse: "L002",
    namecouse: {
      th: "เจ้าหน้าที่ความปลอดภัยในการทำงานระดับบริหาร (จป.บริหาร)",
      en: "Management-Level Occupational Safety Officer (OSO Management)",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "6",
    imagebackground: {
      th: law,
      en: laweng,
    },
    category: "Legal_Compliance",
    codecouse: "L003",
    namecouse: {
      th: "เจ้าหน้าที่ความปลอดภัยในการทำงานระดับเทคนิค (จป.เทคนิค)",
      en: "Technical-Level Occupational Safety Officer (OSO Technical)",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "7",
    imagebackground: {
      th: law,
      en: laweng,
    },
    category: "Legal_Compliance",
    codecouse: "L004",
    namecouse: {
      th: "คณะกรรมการความปลอดภัยอาชีวอนามัยและสภาพแวดล้อมในการทำงานของสถานประกอบกิจการ (คปอ)",
      en: "Occupational Health, Safety, and Environment Committee (OHSEC)",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "8",
    imagebackground: {
      th: law,
      en: laweng,
    },
    category: "Legal_Compliance",
    codecouse: "L005",
    namecouse: {
      th: "การดับเพลิงเบื้องต้น",
      en: "Basic Firefighting",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "9",
    imagebackground: {
      th: law,
      en: laweng,
    },
    category: "Legal_Compliance",
    codecouse: "L006",
    namecouse: {
      th: "การปฐมพยาบาลเบื้องต้น",
      en: "Basic First Aid",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "10",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S01-001",
    namecouse: {
      th: "การบริหารเชิงกลยุทธ์",
      en: "Strategic Management",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "11",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S01-002",
    namecouse: {
      th: "Balanced Scorecard & KPIs",
      en: "Balanced Scorecard & KPIs",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "12",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S01-003",
    namecouse: {
      th: "Competency Based Management",
      en: "Competency Based Management",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "13",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S01-004",
    namecouse: {
      th: "การแก้ปัญหาและตัดสินใจ",
      en: "Problem Solving & Decision Making",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "14",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S01-005",
    namecouse: {
      th: "การบริหารความขัดแย้ง",
      en: "Conflict Management",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "15",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S01-006",
    namecouse: {
      th: "การบริหารเวลาอย่างมีประสิทธิภาพ",
      en: "Effective Time Management",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "16",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S02-001",
    namecouse: {
      th: "ทักษะการเป็นผู้นำ",
      en: "Leadership Skills",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "17",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S02-002",
    namecouse: {
      th: "การบังคับบัญชาอย่างมีประสิทธิภาพ",
      en: "Effective Supervisory Skills",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "18",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S02-003",
    namecouse: {
      th: "การเจรจาต่อรองที่มีประสิทธิภาพ",
      en: "Effective Task Delegation and Management",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "19",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S02-004",
    namecouse: {
      th: "การประเมินผลงานอย่างมีประสิทธิภาพ",
      en: "Effective Motivation Techniques",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "20",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S02-005",
    namecouse: {
      th: "เทคนิคการสอนงานอย่างมีประสิทธิภาพ",
      en: "Effective Performance Appraisal",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "21",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S02-005",
    namecouse: {
      th: "เทคนิคการสอนงานอย่างมีประสิทธิภาพ",
      en: "Effective Coaching Techniques",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "22",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S02-006",
    namecouse: {
      th: "ศาสตร์แห่งการสร้างและรักษาบุคลากรในองค์กร",
      en: "The Art of Building and Retaining Talent in an Organization",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "23",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S03-001",
    namecouse: {
      th: "การสร้างวัฒนธรรมองค์กร",
      en: "How to Build Corporate Culture",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "24",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S03-002",
    namecouse: {
      th: "การทำงานอย่างมีความสุขและสนุกกับงาน",
      en: "Work Smart with Smile",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "25",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S03-003",
    namecouse: {
      th: "การคิดเพื่อมุ่งสู่ความสำเร็จ",
      en: "Thinking for success",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "26",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S03-004",
    namecouse: {
      th: "แผนภูมิทางความคิด",
      en: "Mind Mapping",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "27",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S03-005",
    namecouse: {
      th: "การทำงานเชิงรุก",
      en: "Be Proactive Working",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "28",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S03-006",
    namecouse: {
      th: "ทักษะการประสานงานอย่างมีประสิทธิภาพ",
      en: "Effective Cooperation Skills",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "29",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S03-007",
    namecouse: {
      th: "การทำงานเป็นทีมอย่างมีประสิทธิภาพ",
      en: "Effective Team Building",
    },
    lecturer: "lecturertable",
    detail: "lecturertable",
  },
  {
    id: "30",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S03-008",
    namecouse: {
      th: "การทำงานร่วมกันเป็นทีมด้วยกิจกรรม Walk Rally",
      en: "Teamwork Through Walk Rally Activities",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "31",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S04-001",
    namecouse: {
      th: "ทักษะการสื่อสารอย่างมีประสิทธิภาพ",
      en: "Effective Communication Skills",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "32",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S04-002",
    namecouse: {
      th: "เทคนิคการนำเสนออย่างมีประสิทธิภาพ",
      en: "Effective Presentation Techniques",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "33",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S04-003",
    namecouse: {
      th: "เทคนิคการเป็นวิทยากรอย่างมีประสิทธิภาพ",
      en: "Effective Trainer Techniques",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "34",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S04-004",
    namecouse: {
      th: "เทคนิคการเจรจาต่อรองสู่ความสำเร็จ",
      en: "Successful Negotiation Techniques ",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "35",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S05-001",
    namecouse: {
      th: "การวางแผนและลำดับความสำคัญของงาน",
      en: "Planning & Priority Setting",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "36",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S05-002",
    namecouse: {
      th: "การบริหารทรัพยากรบุคคลสมัยใหม่",
      en: "Modern Human Resource Management",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "37",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S05-003",
    namecouse: {
      th: "การบริหารทรัพยากรบุคคลสำหรับหัวหน้างาน",
      en: "HRM for Supervisor",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "38",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S05-004",
    namecouse: {
      th: "เทคนิคการสัมภาษณ์อย่างมีประสิทธิภาพ",
      en: "Effective Interviewing Techniques",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "39",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S05-005",
    namecouse: {
      th: "การคัดเลือกพนักงานตามหลักนรลักษณ์ศาสตร์ (โหงวเฮ้ง)",
      en: "Employee Selection Based on Physiognomy Principles",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "40",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S05-006",
    namecouse: {
      th: "ระบบพี่เลี้ยง",
      en: "Mentoring System",
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "41",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S05-007",
    namecouse: {
      th: "เทคนิคการให้คำปรึกษา",
      en: "Counseling Techniques"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "42",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S05-008",
    namecouse: {
      th: "E.Q.สร้างคุณค่าของชีวิตการทำงาน",
      en: "E.Q. for Adding Value to Work Life"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "43",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S06-001",
    namecouse: {
      th: "5ส เพื่อการเพิ่มผลผลิต",
      en: "5S for Productivity"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "44",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S06-002",
    namecouse: {
      th: "KAIZEN – Key Success Factor in Productivity",
      en: "KAIZEN – Key Success Factor in Productivity "
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "45",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S06-003",
    namecouse: {
      th: "พัฒนางาน พัฒนาตน ด้วยกิจกรรม QC Circle",
      en: "Work and Self-Development Through QC Circle Activities"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "46",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S06-004",
    namecouse: {
      th: "การลดต้นทุนและค่าใช้จ่าย",
      en: "Cost Reduction"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "47",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S07-001",
    namecouse: {
      th: "เทคนิคการเป็นพนักงานขายอย่างมืออาชีพ",
      en: "Professional Sales Techniques"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "48",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S07-002",
    namecouse: {
      th: "หลักการตลาดสมัยใหม่",
      en: "Modern Marketing"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "49",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S07-003",
    namecouse: {
      th: "กลยุทธ์การบริหารความสัมพันธ์กับลูกค้า",
      en: "Customer Relationship Management Strategy"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "50",
    imagebackground: {
      th: softskill,
      en: softskilleng,
    },
    category: "Soft Skills",
    codecouse: "S07-004",
    namecouse: {
      th: "เทคนิคการให้บริการสู่ความเป็นเลิศ",
      en: "Excellent Service Techniques"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "51",
    imagebackground: {
      th: it,
      en: iteng,
    },
    category: "Computer",
    codecouse: "C001",
    namecouse: {
      th: "การใช้งาน Microsoft Excel 2016 Level 1",
      en: "Microsoft Excel 2016 Level 1 Usage"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "52",
    imagebackground: {
      th: it,
      en: iteng,
    },
    category: "Computer",
    codecouse: "C002",
    namecouse: {
      th: "การใช้งาน Microsoft Excel 2016 Level 2",
      en: "Microsoft Excel 2016 Level 2 Usage"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "53",
    imagebackground: {
      th: it,
      en: iteng,
    },
    category: "Computer",
    codecouse: "C003",
    namecouse: {
      th: "การใช้งาน Microsoft Excel 2016 Level 3",
      en: "Microsoft Excel 2016 Level 3 Usage"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "54",
    imagebackground: {
      th: it,
      en: iteng,
    },
    category: "Computer",
    codecouse: "C004",
    namecouse: {
      th: "การใช้งาน MS-Excel 365 level 1",
      en: "MS-Excel 365 level 1 Usage"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "55",
    imagebackground: {
      th: it,
      en: iteng,
    },
    category: "Computer",
    codecouse: "C005",
    namecouse: {
      th: "การใช้งาน MS-Excel 365 level 2",
      en: "MS-Excel 365 level 2 Usage"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "56",
    imagebackground: {
      th: it,
      en: iteng,
    },
    category: "Computer",
    codecouse: "C006",
    namecouse: {
      th: "การใช้งาน MS-Excel 365 level 3",
      en: "MS-Excel 365 level 3 Usage"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "57",
    imagebackground: {
      th: it,
      en: iteng,
    },
    category: "Computer",
    codecouse: "C007",
    namecouse: {
      th: "การใช้งาน Microsoft Excel Maximizing PivotTable",
      en: "Microsoft Excel Maximizing PivotTable Usage"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "58",
    imagebackground: {
      th: it,
      en: iteng,
    },
    category: "Computer",
    codecouse: "C008",
    namecouse: {
      th: "การใช้งาน Microsoft Access 2016 Level 1",
      en: "Microsoft Access 2016 Level 1 Usage"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "59",
    imagebackground: {
      th: it,
      en: iteng,
    },
    category: "Computer",
    codecouse: "C009",
    namecouse: {
      th: "การใช้งาน Microsoft Access 2016 Level 2",
      en: " Microsoft Access 2016 Level 2 Usage"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    id: "60",
    imagebackground: {
      th: it,
      en: iteng,
    },
    category: "Computer",
    codecouse: "C010",
    namecouse: {
      th: "การใช้งาน Microsoft Access 2016 Level 3",
      en: "Microsoft Access 2016 Level 3 Usage"
    },
    lecturer: "lecturertable",
    detail: "ดูรายละเอียดเพิ่มเติม",
  },
  {
    "id": "61",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C011",
    "namecouse": {
      "th": "การใช้งาน MS-PowerPoint 365",
      "en": "MS-PowerPoint 365 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "62",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C012",
    "namecouse": {
      "th": "การใช้งาน Microsoft Excel Data Analysis with PowerPivot",
      "en": "Microsoft Excel Data Analysis with PowerPivot Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "63",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C013",
    "namecouse": {
      "th": "การใช้งาน Useful Excel Formulas and Functions",
      "en": "Useful Excel Formulas and Functions Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "64",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C014",
    "namecouse": {
      "th": "การใช้งาน Power BI",
      "en": "Power BI Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "65",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C015",
    "namecouse": {
      "th": "การใช้งาน Microsoft Excel Tips & Tricks",
      "en": " Microsoft Excel Tips & Tricks Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "66",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C016",
    "namecouse": {
      "th": "การใช้งาน Useful Excel Formulas and Functions",
      "en": " Useful Excel Formulas and Functions Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "67",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C017",
    "namecouse": {
      "th": "การใช้งาน Microsoft Excel Maximized & Dynamic Chart",
      "en": "Microsoft Excel Maximized & Dynamic Chart Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "68",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C018",
    "namecouse": {
      "th": "การใช้งาน MS-Outlook 2016 level 1",
      "en": "MS-Outlook 2016 level 1 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "69",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C019",
    "namecouse": {
      "th": "การใช้งาน MS-Outlook 2016 level 2",
      "en": "MS-Outlook 2016 level 2 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "70",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C020",
    "namecouse": {
      "th": "การใช้งาน Microsoft Excel 2016 for HR",
      "en": "Microsoft Excel 2016 for HR Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "71",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C021",
    "namecouse": {
      "th": "การใช้งาน MS-Outlook 2016 level 1",
      "en": "MS-Outlook 2016 level 1 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "72",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C022",
    "namecouse": {
      "th": "การใช้งาน MS-Outlook 2016 level 2",
      "en": "MS-Outlook 2016 level 2 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "73",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C023",
    "namecouse": {
      "th": "การใช้งาน Microsoft Word 2016 Level 1",
      "en": "Microsoft Word 2016 Level 1 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "74",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C024",
    "namecouse": {
      "th": "การใช้งาน Microsoft Word 2016 Level 2",
      "en": "Microsoft Word 2016 Level 2 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "75",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C025",
    "namecouse": {
      "th": "การใช้งาน Microsoft Word 2016 Level 3",
      "en": "Microsoft Word 2016 Level 3 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "76",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C026",
    "namecouse": {
      "th": "การใช้งาน Microsoft Project 2016 Level 1",
      "en": "Microsoft Project 2016 Level 1 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "77",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C027",
    "namecouse": {
      "th": "การใช้งาน Microsoft Project 2016 Level 2",
      "en": "Microsoft Project 2016 Level 2 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "78",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C028",
    "namecouse": {
      "th": "การใช้งาน Microsoft PowerPoint 2016 Level 1",
      "en": "Microsoft PowerPoint 2016 Level 1 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "79",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C029",
    "namecouse": {
      "th": "การใช้งาน Microsoft PowerPoint 2016 Level 2",
      "en": "Microsoft PowerPoint 2016 Level 2 Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  },
  {
    "id": "80",
    "imagebackground": {
      "th": it,
      "en": iteng
    },
    "category": "Computer",
    "codecouse": "C030",
    "namecouse": {
      "th": "การใช้งาน Microsoft PowerPoint (Useful Presentation)",
      "en": "Microsoft PowerPoint (Useful Presentation) Usage"
    },
    "lecturer": "lecturertable",
    "detail": "ดูรายละเอียดเพิ่มเติม"
  }
];
export { Couseshow };
