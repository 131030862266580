import Bigimage from "../../components/about/Bigimage";
import Gridicon from "../../components/about/Gridicon";
import Portfolio from "../../components/about/Portfolio";
function About() {
  return (
    <div>
      <Bigimage></Bigimage>
      <Gridicon></Gridicon>
      <Portfolio></Portfolio>
    </div>
  );
}

export default About;
