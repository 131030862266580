import module from "../../assets/components-css/news.module.css";
import {} from "@fortawesome/free-solid-svg-icons";
import Megaphone from "../../assets/image/megaphoneicon.png";
import colorslash from "../../assets/image/colorslash.png";
import ITRC from "../../assets/image/ITRC.png";
import grid1 from "../../assets/image/gridimage1.png";
import grid2 from "../../assets/image/gridimage2.png";
import grid3 from "../../assets/image/gridimage3.png";
import ITRCeng from "../../assets/image/ITRCeng.png";
import grid1eng from "../../assets/image/gridimage1eng.png";
import grid2eng from "../../assets/image/gridimage2eng.png";
import grid3eng from "../../assets/image/gridimage3eng.png";
import background from "../../assets/image/BACKGROUND1.png";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";
function News() {

const { t }: { t: (key: string) => string } = useTranslation();
 const { i18n } = useTranslation();
    return (
    <div className={module.container}>
      <div className={module.new}>
        <div className={module.newtext}>
          <h3>{t("news")}</h3>
          <h1>{t("PublicRelations")}</h1>
          <img src={colorslash} alt="" />
        </div>
        <div className={module.newbutton}>
          <button>
            <div className={module.newbuttontext}>
              <p>{t("btnnews")}</p>
            </div>
            <div className={module.newbuttonimg}>
              <img src={Megaphone} alt="" />
            </div>
          </button>
        </div>
        <img src={background} alt="" className={module.background} />
      </div>
      <div className={module.couseall}>
        <div className={module.bigimage}>
          <h3>{t("poppular_couse")}</h3>
          <img src={i18n.language === "th" ? ITRC : ITRCeng} alt="" />
        </div>
        <div className={module.couse}>
          <div className={module.couseshow}>
            <div className={module.couseimage}>
              <img src={i18n.language === "th" ? grid1 : grid1eng} alt="" />
            </div>
            <div className={module.cousetext}>
              <h5>{t("cousename1")}</h5>
              <p>{t("cousetext1")}</p>
            </div>
          </div>
          <div className={module.couseshow}>
            <div className={module.couseimage}>
              <img src={i18n.language === "th" ? grid2 : grid2eng} alt="" />
            </div>
            <div className={module.cousetext}>
              <h5>{t("cousename2")}</h5>
              <p>{t("cousetext1")}</p>
            </div>
          </div>
          <div className={module.couseshow}>
            <div className={module.couseimage}>
            <img src={i18n.language === "th" ? grid3 : grid3eng} alt="" />
            </div>
            <div className={module.cousetext}>
              <h5>{t("cousename3")}</h5>
              <p>{t("cousetext1")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
