import React from "react";
import module from "../../assets/components-css/footer.module.css";
import { useState } from "react";
import logo from "../../assets/image/logoonly.png";
import iconline from "../../assets/image/lineicon.png";
import iconyoutube from "../../assets/image/youtubeicon.png";
import iconfacebook from "../../assets/image/facebookicon.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";
function Footer() {
  const { t }: { t: (key: string) => string } = useTranslation();

  return (
    <div className={module.container}>
      <div className={module.footerlogo}>
        <img src={logo} alt="" />
      </div>
      <div className={module.gridfooter}>
        <div className={module.footercontent}>
          <div className={module.content}>
            {/* <Link
              to="Couse"
              className={module.link}
              state={{ categoryss: 5, Cousenavbar: "Cousenavbar" }}
            > */}
              <h3>{t("Couse")}</h3>
           {/* / </Link> */}
            {/* <Link
              to="Couse"
              className={module.link}
              state={{ categoryss: 1, Cousenavbar: "Cousenavbar" }}
            > */}
              <p>{t("Qua")}</p>
            {/* </Link> */}
            {/* <Link
              to="Couse"
              className={module.link}
              state={{ categoryss: 2, Cousenavbar: "Cousenavbar" }}
            > */}
              <p>{t("Law")}</p>
            {/* </Link> */}
            {/* <Link
              to="Couse"
              className={module.link}
              state={{ categoryss: 3, Cousenavbar: "Cousenavbar" }}
            > */}
              <p>Soft skills</p>
            {/* </Link> */}
            {/* <Link
              to="Couse"
              className={module.link}
              state={{ categoryss: 4, Cousenavbar: "Cousenavbar" }}
            > */}
              <p>{t("Computer")}</p>
            {/* </Link> */}
          </div>
        </div>
        <div className={module.footercontent}>
          <div className={module.content}>
            {/* <Link
              to="about"
              className={module.link}
              state={{ Aboutnavbar: "Aboutnavbar" }}
            > */}
              <h3>{t("About_Us")}</h3>
            {/* </Link> */}
            {/* <Link
              to="about"
              className={module.link}
              state={{ Aboutnavbar: "Aboutnavbar" }}
            > */}
              <p>{t("About_Us")}</p>
            {/* </Link> */}
            <p>{t("Join_Us")}</p>
          </div>
        </div>
        <div className={module.footercontent}>
          <div className={module.content}>
            {/* <Link to="#" className={module.link}> */}
              <h3>{t("Service")}</h3>
            {/* </Link> */}
            {/* <Link to="about" className={module.link} state={{ Standard: "Standard", Aboutnavbar: "Aboutnavbar" }}> */}
              <p>{t("Standard_Systems_Consulting")}</p>
            {/* </Link> */}
            <p>{t("Training")}</p>
            <p>{t("Meeting_Rooms")}</p>
          </div>
        </div>
        <div className={module.footercontent}>
          <div className={module.content}>
            {/* <Link
              to="Map"
              className={module.link}
              state={{ Contactnavbar: "Contactnavbar" }}
            > */}
              <h3>{t("Contact_Us")}</h3>
            {/* </Link> */}
            <p>{t("company")}</p>
            <p>{t("address")}</p>
            <div className={module.emailphone}>
              <div className={module.email}>
                <p className={module.emailleft}>Email : </p>
                <p>irdconsult@ird.co.th</p>
              </div>
              <div className={module.phone}>
                <p className={module.phoneleft}>Phone : </p>
                <p>094-948-7921</p>
              </div>
            </div>
            <div className={module.iconcontact}>
              <img src={iconline} alt="" />
              <img src={iconfacebook} alt="" />
              <img src={iconyoutube} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
