import React, { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import module from "../../assets/components-css/search-accordian.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Dataaccordian } from "../../utils/dataaccordian";
import { faUpLong } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";

// import image
interface Searchaccordian {
  image: { [key: string]: string };
  header: { [key: string]: string };
  text: { [key: string]: string };
  category: { [key: string]: string };
}
function ontop() {
  window.scrollTo({
    top: 0,
  });
}

function Searchaccordian() {

const { t }: { t: (key: string) => string } = useTranslation();
const { i18n } = useTranslation();
  // เลือกหมวดหมู่
  const [accordiantab, setaccordiantab] = useState(1);
  function searchaccordianshow(shownumbertab: number) {
    setaccordiantab(shownumbertab);
  }

  // const [searchshow, setsearchshow] = useState("");
  const [data, setdata] = useState<Searchaccordian[]>([]);

  useEffect(() => {
    setdata(Dataaccordian);
  }, []);

  // const searchaccordian = useMemo(() => {
  //   return data.filter((x: Searchaccordian) =>
  //     x.header.toLowerCase().includes(searchshow.toLowerCase())
  //   );
  // }, [searchshow, data]);

  //Map
  const cousedata = data.map((data , index) => (
    <div
      className={accordiantab === 1 ? module.gridcardactive : module.gridcard} key={index}
    >
      <div className={module.img}>
        <img src={data.image[i18n.language]} alt="" />
      </div>
      <div className={module.textcard}>
        <div className={module.cousetag}><div className={module.cousetagtext}>{data.category[i18n.language]}</div></div>
        <div className={module.textshow}>
          <h5>{data.header[i18n.language]}</h5>
          <p>{data.text[i18n.language]}</p>
        </div>
      </div>
    </div>
  ));

  return (
    <div className={module.container}>
      <div className={module.search}>
        <h2>{t("detailcouse")}</h2>
        {/* <div className={module.searchicon}>
          <div className={module.iconsearch}>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
          <input
            type="text"
            placeholder="ค้นหาคอร์สฝึกอบรม"
            value={searchshow}
            onChange={(e) => setsearchshow(e.target.value)}
          />
        </div> */}
      </div>
      <div className={module.accordianlist}>
        {/* <div className={module.accordianoverflow}>
          <button
            className={module.accordian}
            onClick={() => {
              searchaccordianshow(1);
            }}
          >
            ทั้งหมด
          </button>
          <button
            className={module.accordian}
            onClick={() => {
              searchaccordianshow(2);
            }}
          >
            ด้านระบบคุณภาพ
          </button>
          <button
            className={module.accordian}
            onClick={() => {
              searchaccordianshow(3);
            }}
          >
            ด้านหลักสูตรตามกฏหมาย
          </button>
          <button
            className={module.accordian}
            onClick={() => {
              searchaccordianshow(4);
            }}
          >
            ด้าน Soft Skills
          </button>
          <button
            className={module.accordian}
            onClick={() => {
              searchaccordianshow(5);
            }}
          >
            ด้าน Computer and IT
          </button>
        </div> */}
      </div>
      <div className={module.gridcontent}>{cousedata}</div>
      <div className={module.pagination}>
        {/* <div className={module.allnumberpage}>
          <div className={module.prevnext}>ก่อนหน้า</div>
          <div className={module.numberpageactive}>1</div>
          <div className={module.numberpage}>2</div>
          <div className={module.numberpage}>3</div>
          <div className={module.numberpage}>4</div>
          <div className={module.numberpage}>5</div>
          <div className={module.numberpage}>6</div>
          <div className={module.prevnext}>ถัดไป</div>
        </div> */}

        <div className={module.ontopbutton}>
          <button onClick={ontop}>
            <FontAwesomeIcon icon={faUpLong} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Searchaccordian;
