import module from "../../assets/components-css/map.module.css";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";
import icon1 from "../../assets/image/redicon1.png";
import icon2 from "../../assets/image/redicon2.png";
import icon3 from "../../assets/image/redicon3.png";
import icon4 from "../../assets/image/redicon4.png";
import icon5 from "../../assets/image/redicon5.png";
import icon6 from "../../assets/image/redicon6.png";
function Map() {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <div className={module.container}>
      <div className={module.contact}>
        <div className={module.header}>
          <h1>INFINITY R&D COMPANY LIMITED</h1>
          <h3>{t("companyabout")}</h3>
          <div className={module.time}>
            <p className={module.dot}>•</p>
            <p>{t("time")}</p>
          </div>
        </div>
        <div className={module.iconandcontact}>
          <div className={module.contacticon}>
            <img src={icon1} alt="" />
            <p>{t("address")}</p>
          </div>
          <div className={module.emailtel}>
            <div className={module.contacticon}>
              <img src={icon2} alt="" />
              <p>irdconsult@ird.co.th</p>
            </div>
            <div className={module.contacticon}>
              <img src={icon6} alt="" />
              <p>094-948-7921</p>
            </div>
          </div>
          <div className={module.contacticon}>
            <img src={icon3} alt="" />
            <p>Infinity R&D Company limited</p>
          </div>
          <div className={module.contacticon}>
            <img src={icon4} alt="" />
            <p>@infinityrd</p>
          </div>
          <div className={module.contacticon}>
            <img src={icon5} alt="" />
            <p>Infinity R&D Company limited</p>
          </div>
        </div>
      </div>
      <div className={module.map}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.6333181729046!2d100.71497079999999!3d13.861035600000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d64ab78977fab%3A0xc20952ab5c99e1f!2zNDE0LzMg4LiW4LiZ4LiZIOC5gOC4peC4teC4ouC4muC4hOC4peC4reC4h-C4quC4reC4hyDguYHguILguKfguIfguJrguLLguIfguIrguLHguJkg4LmA4LiC4LiV4LiE4Lil4Lit4LiH4Liq4Liy4Lih4Lin4LiyIOC4geC4o-C4uOC4h-C5gOC4l-C4nuC4oeC4q-C4suC4meC4hOC4oyAxMDUxMA!5e0!3m2!1sth!2sth!4v1737002958188!5m2!1sth!2sth"></iframe>
      </div>
    </div>
  );
}

export default Map;
