import module from "../../assets/components-css/aboutUs.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import aboutusimg from "../../assets/image/imageaboutus.png";
import colorslash from "../../assets/image/colorslash.png";
import background from "../../assets/image/BACKGROUND1.png";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";

function AboutUs() {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <div className={module.container}>
      <div className={module.aboutus}>
        <h1>{t("header")}</h1>
        <h3>{t("textheader2")}</h3>
        <img src={colorslash} className={module.colorslash} alt="" />
        <p>
        {t("textparagraph")}

        </p>
        <button className={module.mybutton}>
        {t("textbutton")}
          <FontAwesomeIcon className={module.arrow} icon={faArrowRight} />
        </button>
      </div>
      <img className={module.background} src={background} alt="" />
      <div className={module.imgaboutus}>
        <img src={aboutusimg} alt="" />
      </div>
    </div>
  );
}

export default AboutUs;
